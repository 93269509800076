import React from 'react';
import { PortableText } from '@portabletext/react';

import Container from '@/components/common/v5/container';
import { Animation } from './animation';
import CtaButton from '@/components/CtaButton';

export const titlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const Hero = ({ hero }) => {
  const { ctaLinksV2, animationItems, heroAnimation } = hero;

  const subTitlePortableText = {
    block: {
      normal: ({ children }) => <span>{children}</span>,
    },
    marks: {
      highlight: ({ children, value }) => (
        <TextLoop animationItems={animationItems} />
      ),
      link: ({ children, value }) => {
        const rel = !value.href.startsWith('/')
          ? 'noreferrer noopener'
          : undefined;
        return (
          <a href={value.href} rel={rel}>
            {children}
          </a>
        );
      },
    },
  };

  return (
    <Container className="relative z-10">
      <div className="absolute left-hero-gradient-spacing top-[30%] -z-10 h-[15.625rem] w-[37.5rem] bg-gradient-hero blur-[9.1rem] md:top-[50%]" />
      <div className="flex flex-col pb-12 pt-8 md:flex-row md:items-center md:gap-8 lg:py-4">
        <div className="w-full md:w-[43%]">
          {/* <Fade> */}
          <div className="relative z-20 flex max-w-xl flex-col text-left">
            <h1 className="text-3xl leading-tight text-white md:text-5xl lg:text-[3.125rem]">
              <PortableText
                value={hero._rawTitleV2}
                components={titlePortableText as any}
              />
            </h1>
            <div className="mt-4 font-light text-white/80 md:text-xl">
              <PortableText
                value={hero._rawSubTitleV2}
                components={subTitlePortableText as any}
              />
            </div>
            <div className="mt-6 flex w-full flex-wrap justify-start text-center">
              {ctaLinksV2.map((link, index) => (
                <CtaButton key={`cta-link-${index}`} link={link} />
              ))}
            </div>
          </div>
          {/* </Fade> */}
        </div>

        <div className="mx-auto -mb-2 -mt-5 w-full max-md:order-first md:-mb-4 md:-mt-7 md:w-[56%] lg:-mb-8 lg:-mt-12 xl:-mb-16 xl:-mt-[4.2rem]">
          <Animation data={{ animation: heroAnimation }} showPlaceholder />
        </div>
      </div>
    </Container>
  );
};

export default Hero;
